<svelte:options
  customElement={{
    tag: "oc-checkbox-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true, delegateFocus: true }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import {
    ariaDescriptionWithValidation,
    implicitSubmit,
    refireNonComposableNativeEvent,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import { FormLabelIconV1 } from "../../../common/components/FormLabelIcon";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./CheckboxV1.types";

  usePropertyChange();

  export let name: Props["name"] = undefined;
  export let value: Exclude<Props["value"], undefined> = "on";
  export let checked: Exclude<Props["checked"], undefined> = false;
  export let variant: Props["variant"] = "default";
  export let disabled: Props["disabled"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let hint: string | undefined = undefined;
  export let validationMessage: string | undefined = undefined;

  export let internals: ElementInternals;

  export let host: HTMLElement;

  export function resetForm() {
    /*                            */
    checked = host.hasAttribute("checked");
  }

  $: computedVariant = validationMessage ? "error" : variant;

  $: internals?.setFormValue(checked ? value : null);
</script>

<label
  class="checkbox"
  class:checkbox--error={computedVariant === "error"}
  use:stopLabelClickPropagation
>
  <input
    type="checkbox"
    class="checkbox__input"
    bind:checked
    {name}
    {disabled}
    use:refireNonComposableNativeEvent={host}
    use:implicitSubmit={internals}
    use:ariaDescriptionWithValidation={{ validationMessage, hint }}
    aria-label={ocAriaLabel}
  />
  <!-- The box displays the outline and the icon. This is needed because input element doesn't support pseudo elements. -->
  <span class="checkbox__box"></span>
  <span class="checkbox__label">
    <slot />
    <FormLabelIconV1 variant={computedVariant} />
  </span>
</label>

<style lang="scss" global>
  @use "../../../common/scss/mixins/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .checkbox {
    position: relative;
    display: block;

    /*                             */
    padding-left: calc(
      tokens.$oc-component-form-checkbox-container-size + tokens.$oc-component-form-checkbox-gap-x
    );

    color: tokens.$oc-component-form-default-label-color;
    font: tokens.$oc-component-form-field-input-font;
  }

  .checkbox__label {
    /*                            */
    display: flex;
    gap: tokens.$oc-component-form-state-icon-gap-x;
    align-items: baseline;
  }

  .checkbox__input {
    all: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: tokens.$oc-component-form-checkbox-container-size;
    height: tokens.$oc-component-form-checkbox-container-size;

    /*                 */
    @include mixins.focus-styles(tokens.$oc-component-form-checkbox-border-radius);
  }

  .checkbox__box {
    position: absolute;
    top: 0;
    left: 0;
    width: tokens.$oc-component-form-checkbox-container-size;
    height: tokens.$oc-component-form-checkbox-container-size;

    outline: 1px solid tokens.$oc-component-form-default-border-color;
    border-radius: tokens.$oc-component-form-checkbox-border-radius;

    background-color: tokens.$oc-component-form-background-color;
  }

  /*                   */
  .checkbox__input:checked ~ .checkbox__box:before {
    $center-icon: calc(50% - tokens.$oc-component-form-checkbox-icon-size / 2);

    content: "";
    display: block;
    position: absolute;
    left: $center-icon;
    top: $center-icon;
    bottom: $center-icon;
    right: $center-icon;

    mask-image: url("/assets-static/icons/pl_icon_check50.svg");
    background-color: tokens.$oc-component-form-default-icon-color;
  }

  /*                 */
  .checkbox--error {
    color: tokens.$oc-component-form-error-label-color;

    .checkbox__box {
      outline-color: tokens.$oc-component-form-error-border-color;
    }

    .checkbox__input:checked ~ .checkbox__box:before {
      background: tokens.$oc-component-form-error-icon-color;
    }
  }

  /*                 */
  @media (hover: hover) {
    :host(:hover:enabled) {
      .checkbox {
        cursor: pointer;
      }

      .checkbox__box {
        outline-width: tokens.$oc-semantic-focus-outline-offset;
      }
    }
  }

  /*                  */
  :host(:enabled) {
    .checkbox__input:active ~ .checkbox__box {
      outline-width: tokens.$oc-semantic-focus-outline-offset;
    }

    .checkbox:not(.checkbox--error) .checkbox__input:active ~ .checkbox__box {
      outline-color: tokens.$oc-component-form-default-border-color-active;
    }
  }

  /*                    */
  :host(:disabled) {
    .checkbox {
      color: tokens.$oc-component-form-disabled-label-color;
    }

    .checkbox__box {
      outline-color: tokens.$oc-component-form-disabled-border-color;
      background-color: tokens.$oc-component-form-disabled-background-color;

      &:before {
        background: tokens.$oc-component-form-disabled-icon-color;
      }
    }
  }
</style>
