<script lang="ts">
  export let hint: string | undefined = undefined;
  export let disabled: boolean = false;
</script>

{#if hint}
  <small class="hint" class:hint--disabled={disabled} aria-disabled={disabled}>{hint}</small>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .hint {
    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-default-hint-color;

    word-break: break-word; /*                                             */

    &--disabled {
      color: tokens.$oc-component-form-disabled-hint-color;
    }
  }
</style>
