<svelte:options
  customElement={{
    tag: "oc-sheet-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      open: { type: "Boolean" },
      headline: { type: "String" },
      id: { type: "String", reflect: true },
      url: { type: "String" },
      base64Url: { type: "String", attribute: "base64-url" },
      hideHeader: { type: "Boolean", attribute: "hide-header" },
      hideCloseButton: { type: "Boolean", attribute: "hide-close-button" },
      fullHeight: { type: "Boolean", attribute: "full-height" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      noContentPadding: { type: "Boolean", attribute: "no-content-padding" },
      allowedErrorStatusCodes: { type: "Array", attribute: "allowed-error-status-codes" },
    },
  }}
/>

<script lang="ts">
  import { tick } from "svelte";
  /*                                                         */
  import { getPointerType } from "@otto-ec/global-resources/device";
  /*                                                         */
  import { executeInlineScripts } from "@otto-ec/global-resources/hardcore";
  import type { Props } from "./SheetV1.types";
  import { useSlots } from "../../../common/utils/useSlots";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import { CLOSE_TYPES, type CloseTypes, useEvents } from "./SheetV1.events";
  import * as shared from "./SheetV1.content.js";
  import * as getters from "./SheetV1.getters.js";
  import { createContext, closeContext } from "@otto-ec/tracking-bct";
  import {
    getPropsFromContent,
    parseLegacyContent,
    updatePropsFromContent,
  } from "./SheetV1.contentParser.js";
  import {
    activeElement,
    documentScrollingDisabled,
    isBackNavigation,
    openSheet,
    openSheetID,
    sheetHistory,
  } from "./SheetV1.stores.js";
  import {
    computeAriaLabelByHeadlineSlot,
    computeContentOverflow,
    computeDragOffsetY,
    updateOpenSheetId,
    disableDocumentScrolling,
    enableDocumentScrolling,
    goBack,
    computeHostDisplayStyle,
  } from "./SheetV1.dom.js";

  usePropertyChange();

  export let open: Props["open"] = false;
  export let headline: Props["headline"] = undefined;
  export let id: Props["id"] = "";
  export let url: Props["url"] = undefined;
  export let base64Url: Props["base64Url"] = undefined;
  export let hideHeader: Props["hideHeader"] = false;
  export let hideCloseButton: Props["hideCloseButton"] = false;
  export let fullHeight: Props["fullHeight"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;
  export let noContentPadding: Props["noContentPadding"] = false;
  export let allowedErrorStatusCodes: Props["allowedErrorStatusCodes"] = [];

  export let host: HTMLOcSheetV1Element;

  const slots = useSlots();

  let sheetElement: HTMLDivElement;
  let contentElement: HTMLDivElement;
  let closeButtonElement: HTMLOcIconButtonV2Element;
  let backButtonElement: HTMLOcIconButtonV2Element;
  let visible = false; /*                           */
  let displayHidden = true; /*                                      */
  let previousSheet: string | null = null; /*                                               */
  let transition = true; /*                                                                         */
  let loadedContent: DocumentFragment; /*                               */
  let isContentLoadingError = false;
  let contentLoadingError: string = "";
  let contentHeight: number; /*                                                */
  let isScrolling = false;
  let isScrollEnd = false;
  let hasContentOverflow = false; /*                                                    */
  let dragging = false; /*                                                      */
  let touchStartY = 0; /*                                         */
  let dragDistance = 0; /*                                                         */
  let dragOffsetY = "0"; /*                                                              */
  let animationTimeout: number; /*                                             */
  let closeType: CloseTypes | null; /*                                               */
  let hasBackButton = false;
  let isTouchableDevice = false; /*                                                             */

  const OPEN_CLOSE_ANIMATION_DURATION = 1000;
  const { emitEvent } = useEvents();

  getPointerType().then((pointerType) => {
    isTouchableDevice = pointerType.isTouchable;
  });

  function handleESCKey(e: KeyboardEvent) {
    if (e.code === "Escape" && open) {
      closeType = CLOSE_TYPES.CLOSED_WITH_ESCAPE;
      open = false;
    }
  }

  function manageFocus(e: MouseEvent) {
    if (e.detail === 0 && $activeElement) {
      $activeElement.focus();
    }
  }

  function onCloseButtonClick(e: MouseEvent) {
    manageFocus(e);
    closeType = CLOSE_TYPES.CLOSED_WITH_X;
    open = false;
  }

  async function onBackdropClick() {
    closeType = CLOSE_TYPES.CLOSED_WITH_CURTAIN_CLICK;
    open = false;
  }

  function onSwipeDown() {
    closeType = CLOSE_TYPES.CLOSED_WITH_DRAG;
    open = false;
  }

  function addToHistory(sheetID: string) {
    if (!$isBackNavigation) {
      /*                                  */
      $sheetHistory = [...$sheetHistory, sheetID];
    } else {
      $isBackNavigation = false;
    }
  }

  /**
 *
 */
  function onScroll() {
    const scrollY = contentElement.scrollTop;
    isScrolling = scrollY > 0;
    isScrollEnd =
      contentElement.offsetHeight + contentElement.scrollTop >= contentElement.scrollHeight - 1;
  }

  function onTouchStartHeader(event: TouchEvent) {
    dragging = true;
    touchStartY = event.touches[0].clientY;
  }

  function onTouchStartContent(event: TouchEvent) {
    if (!isScrolling) {
      touchStartY = event.touches[0].clientY;
    }
  }

  function onTouchMoveContent(event: TouchEvent) {
    if (touchStartY <= 0) {
      return;
    }

    dragDistance = event.touches[0].clientY - touchStartY;
    /*                                                                                 */
    /*                                                               */
    dragging = !isScrolling && dragDistance > 2;

    if (dragging && event.cancelable) {
      /*                                                     */
      event.preventDefault();
    }
  }

  function onTouchMoveHeader(event: TouchEvent) {
    if (!dragging) {
      return;
    }

    dragDistance = event.touches[0].clientY - touchStartY;

    if (event.cancelable) {
      /*                                                     */
      event.preventDefault();
    }
  }

  function onTouchEnd() {
    const CLOSING_DISTANCE = 100;

    if (dragging && dragDistance > CLOSING_DISTANCE) {
      onSwipeDown();
    }

    dragDistance = 0;
    dragging = false;
    touchStartY = 0;
  }

  /*                                                                                               */
  function trapFocus() {
    /*                                                       */
    if (import.meta.env.STORYBOOK && window.parent !== window.self) {
      /*                                      */
      return;
    }

    if (hasBackButton) {
      backButtonElement.focus();
      return;
    }

    closeButtonElement.focus();
  }

  function reset() {
    transition = true;
    $sheetHistory = [];
    $documentScrollingDisabled = enableDocumentScrolling($documentScrollingDisabled);
    $openSheet = null;
    $activeElement = null;
    closeType = null;
  }

  async function showSheet() {
    /*                                                                              */
    displayHidden = false;
    /*                   */
    open = true;
    /*                                     */
    await tick();
    visible = true;
    sheetElement.focus();

    /*                                                          */
    window.document.addEventListener("keydown", handleESCKey);
  }

  function hideSheet() {
    visible = false;
    open = false; /*                   */
    /*                                                */
    animationTimeout = window.setTimeout(() => {
      displayHidden = true;
    }, OPEN_CLOSE_ANIMATION_DURATION);

    /*                                */
    window.document.removeEventListener("keydown", handleESCKey);
  }

  async function loadContent() {
    const contentURL = base64Url ? atob(base64Url) : url;
    if (!contentURL) {
      return;
    }

    try {
      /*                                                                  */
      shared.assertContentURL(contentURL);

      /*                                                                  */
      const contentData = await shared.fetchFromUrl(contentURL, {
        /*                        */
        allowedCodes: allowedErrorStatusCodes!,
      });

      /*                                                                     */
      loadedContent = shared.createDocumentFragment(contentData);

      /*                                                                                                        */
      createContext(id, contentURL, { ts_OttoComponent: ["sheet@v1"] });

      /*                       */
      await tick();

      /*                                                     */
      host.innerHTML = "";

      /*                                                   */
      const loadedOptions = getPropsFromContent(loadedContent);
      updatePropsFromContent(host, $$props, loadedOptions);

      const parsedLoadedContent = parseLegacyContent(loadedContent);
      host.appendChild(parsedLoadedContent);

      executeInlineScripts(host);

      /*                   */
      emitEvent("contentLoaded", { instance: $openSheet! });
    } catch (error) {
      isContentLoadingError = true;

      if (error instanceof shared.LoadError && error.errorContent) {
        /*                                      */
        contentLoadingError = error.errorContent;
        /*                                        */
      }
      emitEvent("contentLoadingError", { instance: $openSheet!, error });
    }
  }

  async function openThisSheet() {
    /*                                       */
    clearTimeout(animationTimeout);
    $openSheet = host;

    /*                                    */
    if (previousSheet) {
      transition = false;
      /*                                          */
      addToHistory(previousSheet);
      await showSheet();
      emitEvent("switch", { instance: $openSheet });
    } else {
      transition = true;

      $documentScrollingDisabled = disableDocumentScrolling($documentScrollingDisabled);

      /*                                                                             */
      $activeElement = (window.document.activeElement as HTMLElement) ?? null;
      await showSheet();
      emitEvent("open", { instance: $openSheet });
    }

    if ((url || base64Url) && !loadedContent) {
      await loadContent();
    }

    ocAriaLabel = computeAriaLabelByHeadlineSlot(host, ocAriaLabel);
  }

  function closeThisSheet(_openSheetID: string | null) {
    if (loadedContent) {
      /*                                                  */
      loadedContent && closeContext();
    }

    /*                                         */
    if (_openSheetID) {
      transition = false;
      hideSheet();
      return;
    }

    /*                                                             */
    hideSheet();
    closeType = closeType ?? CLOSE_TYPES.CLOSED_PROGRAMMATICALLY;
    emitEvent("close", { instance: $openSheet!, closeType });
    reset();
  }

  function onSheetChange(_openSheetID: string | null) {
    if (_openSheetID === id) {
      openThisSheet();
    } else if (_openSheetID !== id && visible) {
      closeThisSheet(_openSheetID);
    }

    /*                             */
    previousSheet = _openSheetID;
  }

  /*                 */
  $: updateOpenSheetId(openSheetID, id, open);
  $: onSheetChange($openSheetID);
  $: host.style.display = computeHostDisplayStyle(displayHidden);
  $: hasContentOverflow = computeContentOverflow(contentElement, contentHeight);
  /*                                                                  */
  $: dragOffsetY = computeDragOffsetY(dragging, dragDistance);
  $: hasBackButton = !!$sheetHistory.length;

  /*               */
  export const getContent = getters.getContent.bind(null, host);
  export const getHeader = getters.getHeader.bind(null, host);
  export const getActions = getters.getActions.bind(null, host);

  /*                                                                                           */
  /*                                                                                                    */
  if (!import.meta.env.STORYBOOK) {
    document.body.append(host);
  }
</script>

{#if id}
  <div
    class="sheet"
    class:sheet--open={visible}
    class:sheet--no-transition={!transition}
    class:sheet--no-header={hideHeader}
    class:sheet--full-height={fullHeight}
    class:sheet--is-touchable={isTouchableDevice}
    class:sheet--no-content-padding={noContentPadding}
  >
    <!-- Focus trap start: triggers .sheet__sheet:not(:focus-within) on focus  -->
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <span class="sheet__focus-trap" tabindex="0" aria-hidden="true"></span>
    <div class="sheet__backdrop" on:click={onBackdropClick} aria-hidden="true"></div>
    <div
      class="sheet__sheet"
      bind:this={sheetElement}
      class:sheet__sheet--loaded={loadedContent}
      style={dragging ? `transform:translateY(${dragOffsetY})` : ""}
      role="dialog"
      aria-label={ocAriaLabel || headline}
      aria-describedby="dialog-headline"
      aria-modal="true"
      tabindex="-1"
    >
      <!--SHEET HEADER-->
      <div
        class="sheet__header"
        class:sheet__header--with-shadow={isScrolling && !hideHeader}
        on:touchstart={onTouchStartHeader}
        on:touchmove={onTouchMoveHeader}
        on:touchend={onTouchEnd}
      >
        {#if hasBackButton}
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <oc-icon-button-v2
            bind:this={backButtonElement}
            on:click={goBack}
            icon="arrow-left"
            elevation={hideHeader ? "200" : "0"}
            class="sheet__back-button"
            ocAriaLabel="zurück"
          />
        {/if}
        <div class="sheet__headline-outer">
          <slot name="headline">
            {#if headline}<h3 class="sheet__headline" id="dialog-headline">{headline}</h3>{/if}
          </slot>
        </div>
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <oc-icon-button-v2
          bind:this={closeButtonElement}
          on:click={onCloseButtonClick}
          class:sheet__close-button--hidden={hideCloseButton}
          icon="close"
          elevation={hideHeader ? "200" : "0"}
          class="sheet__close-button"
          ocAriaLabel="schließen"
        />
      </div>
      <!--SHEET CONTENT-->
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <div
        class="sheet__content"
        tabindex="0"
        class:sheet__content--no-scrolling={dragging}
        on:scroll={onScroll}
        bind:this={contentElement}
        bind:clientHeight={contentHeight}
        on:touchstart={onTouchStartContent}
        on:touchmove|stopPropagation={onTouchMoveContent}
        on:touchend={onTouchEnd}
      >
        {#if !isContentLoadingError}
          <!--CONTENT SLOT-->
          <slot />
        {:else}
          <!--LOADING-ERROR SLOT-->
          <div class="sheet_loading-error">
            <slot name="loading-error">{@html contentLoadingError}</slot>
          </div>
        {/if}
      </div>

      <!--SHEET FOOTER-->
      {#if $slots.actions}
        <div
          class="sheet__footer"
          class:sheet__footer--with-shadow={hasContentOverflow && !isScrollEnd}
        >
          <div class="sheet__actions">
            <slot name="actions" />
          </div>
        </div>
      {/if}
      <span on:transitionend={trapFocus} class="sheet__focus-helper"></span>
    </div>
    <!-- Focus trap end: triggers .sheet__sheet:not(:focus-within) on focus -->
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <span class="sheet__focus-trap" tabindex="0" aria-hidden="true"></span>
  </div>
{:else}
  <!--  eslint-disable-next-line svelte/no-at-html-tags-->
  {@html "<!-- Property ID is missing! Please set the 'id' attribute/property to a unique value -->"}
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  ::slotted([slot="headline"]) {
    /*                                    */
    margin-top: 4px !important;
    margin-bottom: 16px !important;
  }

  .sheet {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9001;
    overscroll-behavior-y: none;

    &__backdrop {
      position: fixed;
      right: 0;
      bottom: 0;
      background-color: tokens.$oc-component-sheet-scrim-color;
      opacity: 0;
      transition: opacity ease 0.3s;
    }

    &__sheet {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      top: initial;
      max-height: 80vh;
      max-height: 80dvh;
      display: flex;
      flex-direction: column;
      background: tokens.$oc-component-sheet-content-default-background-color;
      transform: translateY(100%);
      transition: all tokens.$oc-component-sheet-show-duration
        tokens.$oc-component-sheet-show-easing;
      border-radius: tokens.$oc-component-sheet-border-radius
        tokens.$oc-component-sheet-border-radius 0 0;
      overflow: auto;

      &:focus {
        outline: none;
      }
    }

    &__header {
      position: relative;
      z-index: 1;
      display: flex;
      gap: tokens.$oc-component-sheet-header-gap-x;
      align-items: flex-start;
      padding: 20px 12px 0 16px;
      min-height: 44px; /*                                                  */
      flex-shrink: 0;
      background: tokens.$oc-component-sheet-header-background-color;
      border-bottom: tokens.$oc-component-sheet-header-conditional-border-bottom-width solid
        transparent;
      transition: border-color tokens.$oc-component-sheet-conditional-border-transition-duration
        tokens.$oc-component-sheet-conditional-border-transition-easing;

      &--with-shadow {
        border-color: tokens.$oc-component-sheet-header-conditional-border-bottom-color;
      }
    }

    &__headline-outer {
      flex: 1 0;
    }

    &__headline {
      flex: 1 1 auto;
      color: tokens.$oc-component-sheet-header-title-color;
      font: tokens.$oc-component-sheet-header-title-font;
      /*                                                                                */
      margin-top: 4px;
      margin-bottom: tokens.$oc-semantic-spacing-100;
      user-select: none;
    }

    &__back-button {
      margin-left: -4px;
    }

    &__close-button {
      &--hidden {
        /*                                 */
        width: 0;
        height: 0;
        display: block;
        overflow: hidden;
      }
    }

    &__content {
      flex-grow: 0;
      overflow: auto;
      overscroll-behavior: contain;
      min-height: 72px; /*                                                                     */
      padding: tokens.$oc-semantic-spacing-50 tokens.$oc-semantic-spacing-100
        tokens.$oc-semantic-spacing-100;

      &:focus {
        outline-offset: -2px !important;
        & {
          @include mixins.focus-styles(tokens.$oc-component-button-50-border-radius);
        }
      }

      &--no-scrolling {
        overflow: hidden;
      }
    }

    &__footer {
      padding: tokens.$oc-semantic-spacing-100 tokens.$oc-semantic-spacing-100 30px
        tokens.$oc-semantic-spacing-100;
      border-top: tokens.$oc-component-sheet-action-bar-conditional-border-top-width solid
        transparent;
      transition: border-color tokens.$oc-component-sheet-conditional-border-transition-duration
        tokens.$oc-component-sheet-conditional-border-transition-easing;

      &--with-shadow {
        background-color: tokens.$oc-component-sheet-action-bar-background-color;
        border-color: tokens.$oc-component-sheet-action-bar-conditional-border-top-color;
      }

      slot {
        filter: none;
      }
    }

    &__actions {
      filter: none;
    }

    &__focus-trap {
      /*                                 */
      width: 0;
      height: 0;
      display: block;
      overflow: hidden;
    }

    &__focus-helper {
      /*                                  */
      width: 0;
      height: 0;
      display: block;
      opacity: 0;
    }
  }

  /*        */
  .sheet--open {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .sheet__sheet {
      transform: translateY(0);

      /*                                   */
      /*                                                                                                      */
      /*                                                                        */
      &:not(:focus-within) .sheet__focus-helper {
        transition: opacity 0.01s;
        opacity: 0.1;
      }
    }

    .sheet__backdrop {
      left: 0;
      top: 0;
      opacity: 0.75;
    }
  }

  .sheet--no-transition {
    .sheet__backdrop {
      transition: none;
    }
  }

  .sheet--no-header {
    .sheet__header {
      background: none;
    }

    .sheet__headline {
      display: none;
    }

    .sheet__back-button {
      margin-top: 0;
    }

    .sheet__content {
      margin-top: -65px; /*                       */
      padding-top: tokens.$oc-semantic-spacing-100;
    }
  }

  .sheet--full-height {
    .sheet__sheet {
      height: 80vh;
      height: 80dvh;
    }
    .sheet__content {
      flex-grow: 1;
    }
  }

  .sheet--full-height.sheet--no-transition {
    .sheet__sheet {
      transition: none;
    }
  }

  .sheet--is-touchable {
    .sheet__header::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: tokens.$oc-component-sheet-drag-indicator-spacing-top;
      width: tokens.$oc-component-sheet-drag-indicator-width;
      height: tokens.$oc-component-sheet-drag-indicator-height;
      border-radius: tokens.$oc-component-sheet-drag-indicator-border-radius;
      background-color: tokens.$oc-component-sheet-drag-indicator-color;
    }
  }

  .sheet--no-content-padding {
    .sheet__content {
      padding: 0;
    }
  }

  /*                                    */
  /*                                                                            */
  @media (min-width: 480px) {
    .sheet {
      &__sheet {
        top: 0;
        left: initial;
        width: 448px;
        max-height: none;
        transform: translateX(100%);
        border-radius: tokens.$oc-component-sheet-border-radius 0 0
          tokens.$oc-component-sheet-border-radius;
      }
    }

    .sheet--open {
      .sheet__sheet {
        /*                                                                                                 */
        /*                                      */
        transform: translateX(0) !important;
      }

      .sheet__header {
        /*                                                                     */
        pointer-events: none;
      }

      .sheet__back-button,
      .sheet__close-button {
        /*                                               */
        pointer-events: all;
      }
    }
    .sheet--no-transition {
      .sheet__sheet {
        transition: none;
      }
    }
    .sheet--full-height {
      .sheet__sheet {
        height: auto;
      }
    }
    .sheet--is-touchable {
      .sheet__header::after {
        content: none;
      }
    }
  }
</style>
