<svelte:options
  customElement={{
    tag: "oc-toggletip-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
    }),
    props: {
      position: { type: "String" },
      visible: { type: "Boolean", reflect: true },
      headline: { type: "String" },
      iconType: { type: "String", attribute: "icon-type" },
    },
  }}
/>

<script lang="ts">
  import { useSlots } from "../../../common/utils/useSlots";
  import { useTooltipPosition } from "../../../common/utils/useTooltipPosition";
  import type { Props } from "./ToggletipV1.types";

  export let position: Props["position"];
  export let visible: Props["visible"];
  export let headline: Props["headline"];
  export let iconType: Props["iconType"];

  visible ??= false;

  const slots = useSlots();

  let closeButton: HTMLElement;

  let element: HTMLElement;
  let focusReturn: HTMLElement | null = null;

  const pos = useTooltipPosition();

  $: pos.reposition(element, position);

  function open(target: EventTarget | null, manageFocus: boolean) {
    if (visible) return;
    visible = true;
    pos.reposition(element, position);
    if (manageFocus) {
      if (target instanceof HTMLElement) focusReturn = target;
      setTimeout(() => closeButton.focus());
    }
  }

  function close(manageFocus: boolean) {
    if (!visible) return;
    visible = false;
    if (manageFocus) focusReturn?.focus();
    focusReturn = null;
  }

  window.addEventListener("resize", () => close(true));

  function onToggleClick(evt: MouseEvent) {
    const manageFocus = evt.detail === 0;
    if (visible) close(manageFocus);
    else open(evt.target, manageFocus);
  }

  function onCloseClick(evt: MouseEvent) {
    close(evt.detail === 0);
  }

  function onContentKeyup(evt: KeyboardEvent) {
    if (evt.key === "Escape") close(true);
  }

  let closeOnClick = true;

  function onBoxClick() {
    closeOnClick = false;
  }

  document.addEventListener("click", () => {
    if (closeOnClick) close(false);
    closeOnClick = true;
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="toggletip-box"
  data-position-v={$pos.vertical}
  data-position-h={$pos.horizontal}
  on:click={onBoxClick}
>
  <div class="activator" on:click={onToggleClick}><slot /></div>
  <div class="toggletip-anchor" class:hidden={!visible} class:positioning={$pos.positioning}>
    <span class="arrow" />
    <div
      class="body"
      bind:this={element}
      role="dialog"
      aria-labelledby={headline ? "toggletip-headline" : "toggletip-content"}
      aria-describedby="toggletip-content"
      aria-hidden={!visible}
      on:keyup={onContentKeyup}
    >
      <div class="main">
        {#if iconType}
          <div class="icon-wrapper"><oc-icon-v1 type={iconType} color="#fff"></oc-icon-v1></div>
        {/if}
        <div class="inline-wrapper">
          <span class="close-button-wrapper">
            <oc-icon-button-v2
              class="close-button"
              variant="inverted-transparent"
              icon="close"
              ocAriaLabel="Schließen"
              bind:this={closeButton}
              on:click={onCloseClick}
            /></span
          >
          <span class="content">
            {#if headline}
              <h1 id="toggletip-headline" class="headline">{headline}</h1>
            {/if}
            <slot name="toggletip-content" id="toggletip-content" />
          </span>
        </div>
      </div>
      {#if $slots["toggletip-actions"]}
        <div class="actions">
          <slot name="toggletip-actions" />
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    display: inline-block;
    line-height: 0;
  }

  $verticalOffset: calc(
    tokens.$oc-component-toggletip-context-spacing + tokens.$oc-component-toggletip-arrow-height
  );

  .toggletip-box {
    line-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &[data-position-v="bottom"] {
      flex-direction: column;

      .toggletip-anchor {
        justify-content: flex-end;
      }

      .arrow {
        transform: translateY($verticalOffset);
      }

      .body {
        transform: translateY(calc(100% + $verticalOffset));
      }
    }

    &[data-position-v="top"] {
      flex-direction: column-reverse;

      .toggletip-anchor {
        justify-content: flex-start;
      }

      .arrow {
        transform: rotate(180deg) translateY($verticalOffset);
      }

      .body {
        transform: translateY(calc(-100% - $verticalOffset));
      }
    }

    &[data-position-h="left"] {
      .body {
        left: tokens.$oc-component-toggletip-frame-spacing;
      }
    }

    &[data-position-h="right"] {
      .body {
        right: tokens.$oc-component-toggletip-frame-spacing;
      }
    }
  }

  .toggletip-anchor {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    transition: opacity tokens.$oc-component-toggletip-show-hide-transition-duration
      tokens.$oc-component-toggletip-show-hide-transition-easing;

    &.hidden {
      transition:
        opacity tokens.$oc-component-toggletip-show-hide-transition-duration
          tokens.$oc-component-toggletip-show-hide-transition-easing,
        visibility 0ms tokens.$oc-component-toggletip-show-hide-transition-duration;
      visibility: hidden;
      opacity: 0;
    }

    &.positioning {
      opacity: 0;
      /*                                                                                                                       */
      overflow: hidden;
      /*                                                                                                                               */
      position: relative;
    }
  }

  .arrow {
    $seamFixHeight: 1px;

    width: calc(tokens.$oc-component-toggletip-arrow-width + $seamFixHeight * 2);
    height: calc(tokens.$oc-component-toggletip-arrow-height + $seamFixHeight);
    margin-bottom: -$seamFixHeight;
    background-color: tokens.$oc-component-toggletip-background-color;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    transform-origin: center;
    position: absolute;
  }

  .body {
    position: absolute;
    box-sizing: border-box;
    max-width: min(
      tokens.$oc-component-toggletip-max-width,
      calc(100vw - 2 * tokens.$oc-component-toggletip-frame-spacing)
    );
    background-color: tokens.$oc-component-toggletip-background-color;
    padding: tokens.$oc-component-toggletip-close-button-spacing 8px
      tokens.$oc-component-toggletip-action-spacing-bottom
      tokens.$oc-component-toggletip-text-spacing;
    color: tokens.$oc-component-toggletip-copy-color;
    border-radius: tokens.$oc-component-toggletip-border-radius;
    font: tokens.$oc-component-toggletip-copy-font;
  }

  .main {
    display: flex;
  }

  .icon-wrapper {
    padding-top: tokens.$oc-component-toggletip-gap-y;
    padding-right: tokens.$oc-component-toggletip-gap-x;
  }

  .inline-wrapper {
    display: inline-block;
    width: max-content;
    max-width: 100%;
    padding: tokens.$oc-component-toggletip-gap-y 0 tokens.$oc-component-toggletip-gap-y;
    hyphens: auto;
    overflow-wrap: break-word;
  }

  .headline {
    font: tokens.$oc-component-toggletip-headline-font;
    margin-top: 0;
    margin-bottom: tokens.$oc-component-toggletip-gap-y;
  }

  .content {
    float: none;
  }

  .actions {
    text-align: right;
  }

  .close-button-wrapper {
    min-height: calc(1lh + 2 * tokens.$oc-component-toggletip-gap-y);
    display: inline-flex;
    align-items: center;
    float: right;
    margin: calc(-1 * tokens.$oc-component-toggletip-gap-y) 0
      calc(-1 * tokens.$oc-component-toggletip-gap-y) tokens.$oc-component-toggletip-gap-x;
  }
</style>
