import { get, type Writable } from "svelte/store";
import { isBackNavigation, openSheetID, sheetHistory } from "./SheetV1.stores";
import { disableScrolling, enableScrolling } from "../../../common/utils/scrollPrevention";

/**
 *
 *
 *
 *
 */
export function goBack(): void {
  let previousSheetID: string | undefined;

  sheetHistory.update((history) => {
    previousSheetID = history.pop();
    return history;
  });

  if (previousSheetID) {
    openSheetID.set(previousSheetID);
    isBackNavigation.set(true);
  }
}

/**
 *
 *
 *
 *
 *
 */
export function disableDocumentScrolling(documentScrollingDisabled: boolean): boolean {
  if (import.meta.env.STORYBOOK && window.parent !== window.self) {
    /*                                  */
    /*                                                                      */
    return documentScrollingDisabled;
  }

  /*                                             */
  if (documentScrollingDisabled) {
    /*                                                        */
    return documentScrollingDisabled;
  }

  disableScrolling();
  return true;
}

/**
 *
 *
 *
 *
 *
 */
export function enableDocumentScrolling(documentScrollingDisabled: boolean): boolean {
  if (import.meta.env.STORYBOOK && window.parent !== window.self) {
    /*                                  */
    /*                                                 */
    return documentScrollingDisabled;
  }

  /*                                           */
  if (!documentScrollingDisabled) {
    /*                                                        */
    return documentScrollingDisabled;
  }

  enableScrolling();
  return false;
}

/**
 *
 *
 *
 *
 */
export function computeHostDisplayStyle(displayHidden: boolean): string {
  return displayHidden ? "none" : "";
}

/**
 *
 *
 *
 *
 *
 *
 */
export function updateOpenSheetId(
  openSheetId: Writable<string | null>,
  id: string,
  isOpen: boolean | undefined,
): void {
  if (isOpen) {
    /*                                                                   */
    openSheetID.set(id);
  }

  /*                                                       */
  if (!isOpen && get(openSheetId) === id) {
    /*                                                                 */
    openSheetID.set(null);
  }

  /*                                                                                      */
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function computeAriaLabelByHeadlineSlot(
  host: HTMLElement,
  ocAriaLabel?: string,
): string | undefined {
  /*                                                                                 */
  return ocAriaLabel || host.querySelector(`[slot="headline"]`)?.textContent || undefined;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function computeContentOverflow(
  contentElement: HTMLElement | undefined | null,
  contentHeight: number,
): boolean {
  return !!contentElement && contentElement.scrollHeight !== contentHeight;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function computeDragOffsetY(isDragging: boolean, dragDistance: number): string {
  return isDragging && dragDistance > 0 ? `${dragDistance}px` : "0";
}
