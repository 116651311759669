/**
 *
 *
 *
 */
export function createDocumentFragment(text: string): DocumentFragment {
  const template = document.createElement("template");
  template.innerHTML = text;
  return template.content;
}

/**
 *
 *
 *
 *
 */
export function assertContentURL(url: string): asserts url is string {
  let normalizedURL: URL;
  try {
    normalizedURL = new URL(url, window.location.href);
  } catch (err) {
    throw new Error(`Coudl not parse given URL: ${url}`);
  }

  /*                                                                     */
  if (
    /*                                              */
    normalizedURL.hostname !== window.location.hostname &&
    !normalizedURL.hostname.endsWith("otto.de")
  ) {
    throw new Error(`URL hostname is not allowed in: ${url}`);
  }
}

/**
 *
 */
export class LoadError extends Error {
  /**
 *
 */
  name = "LoadError";

  /**
 *
 */
  status: number;

  /**
 *
 */
  response: Response;

  /**
 *
 *
 */
  errorContent?: string;

  constructor(message: string, response: Response, errorContent: string | undefined) {
    super(message);
    /*                                                */
    this.status = response.status || 300;
    this.response = response;
    this.errorContent = errorContent;
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export async function fetchFromUrl(
  url: string,
  options: { allowedCodes: number[] },
): Promise<string> {
  /*                                        */
  const controller = new AbortController();

  /*                          */
  const response = await fetch(url, {
    redirect: "manual",
    headers: { Accept: "text/html" },
    signal: controller.signal,
  });

  /*                                                                      */
  if (response.ok && !!response.headers.get("Content-Type")?.includes("html")) {
    /*                     */
    return response.text();
  }

  /*                   */
  if (
    /*                                                                                  */
    !!response.headers.get("Cache-Control")?.includes("no-transform") &&
    /*                                                                                  */
    (options.allowedCodes.length === 0 || options.allowedCodes.includes(response.status))
  ) {
    /*                                                   */
    throw new LoadError("Error Response", response, await response.text());
  }

  /*                                                                         */
  controller.abort();
  throw new LoadError("Bad response", response, undefined);
}
