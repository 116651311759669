/**
 *
 *
 */
import { createDocumentFragment } from "./SheetV1.content";
import type { OcSheetV1Props } from "./SheetV1.types.g";

const HEADLINE_SLOT_ATTR = 'slot="headline"';

/**
 *
 *
 *
 *
 *
 *
 */
export function parseLegacyContent(content: DocumentFragment): DocumentFragment {
  /*                                                      */
  /*                                                           */
  if (content.querySelector(`[${HEADLINE_SLOT_ATTR}]`)) {
    return content;
  }

  /*                                     */
  const headline = content.querySelector<HTMLElement>(`[data-title]`)?.dataset.title;
  if (!headline) {
    return content;
  }

  /*                                                            */
  const headlineSlotFragment = createDocumentFragment(
    `<h3 class="oc-headline-100" ${HEADLINE_SLOT_ATTR}>${headline}</h3>`,
  );
  content.append(headlineSlotFragment);
  return content;
}

export function getPropsFromContent(content: DocumentFragment): Partial<OcSheetV1Props> {
  const script = content.querySelector<HTMLScriptElement>("script[data-oc-sheet-v1-configure]");
  if (!script) {
    return {};
  }

  const data = script?.textContent;
  if (!data) {
    return {};
  }

  try {
    /*                       */
    content.removeChild(script);
    return JSON.parse(data) as never;
    /*                                                         */
  } catch (_e) {
    return {};
  }
}

export function updatePropsFromContent(
  host: HTMLOcSheetV1Element,
  $$props: Record<string, unknown>,
  props: Partial<OcSheetV1Props>,
): void {
  Object.entries(props).forEach(([key, value]) => {
    if (!$$props[key]) {
      host[key as never] = value as never;
    }
  });
}
