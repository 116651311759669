/*                                                         */
import { defineEvent } from "@otto-ec/global-resources/nexus";
import type { AsyncNamespaceInput, FunctionImplementation } from "@otto-ec/global-resources/nexus";
import { get } from "svelte/store";
/*                                                         */
import { delegate } from "@otto-ec/global-resources/event";
/*                                                         */
import { nanoid } from "@otto-ec/global-resources/misc";
/*                                                         */
import { parseParameters } from "@otto-ec/global-resources/attribute-parser";
import * as getters from "./SheetV1.getters";
import type {
  SheetV1Back,
  SheetV1Close,
  SheetV1Create,
  SheetV1GetActions,
  SheetV1GetContent,
  SheetV1GetHeader,
  SheetV1GetOpenInstance,
  SheetV1Open,
} from "./index.js";
import type { OcSheetV1Props } from "./SheetV1.types.g";
import { getSheetElement } from "./SheetV1.getters";
import { openSheet, openSheetID } from "./SheetV1.stores";
import { goBack } from "./SheetV1.dom.js";
/*                                            */

/*                                                             */
/*                 */
const DATA_SHEET_ID = "data-oc-sheet-id";
/*                                                       */
const DATA_SHEET_OPEN = "data-oc-sheet-v1-open";
/*                                                        */
const DATA_SHEET_CREATE = "data-oc-sheet-v1-create";

export const open = function open(sheetID) {
  openSheetID.set(sheetID);
} satisfies FunctionImplementation<SheetV1Open>;

export const close = function close() {
  openSheetID.set(null);
} satisfies FunctionImplementation<SheetV1Close>;

export const back = goBack satisfies FunctionImplementation<SheetV1Back>;

export const create = function create(config: OcSheetV1Props) {
  const sheet = document.createElement("oc-sheet-v1") as HTMLOcSheetV1Element;

  sheet.id = config.id ?? nanoid();
  sheet.open = config.open ?? true;
  sheet.headline = config.headline;
  sheet.url = config.url;
  sheet.base64Url = config.base64Url;
  sheet.hideHeader = config.hideHeader;
  sheet.hideCloseButton = config.hideCloseButton;
  sheet.fullHeight = config.fullHeight;
  sheet.ocAriaLabel = config.ocAriaLabel;
  sheet.noContentPadding = config.noContentPadding;

  document.body.append(sheet as Node);

  return sheet;
} satisfies FunctionImplementation<SheetV1Create>;

export const getOpenSheet = function getOpenSheetInstance() {
  return get(openSheet);
} satisfies FunctionImplementation<SheetV1GetOpenInstance>;

export const getContent = function getContent() {
  const openSheetElement = getOpenSheet();
  return openSheetElement ? getters.getContent(openSheetElement) : null;
} satisfies FunctionImplementation<SheetV1GetContent>;

export const getHeader = function getHeader() {
  const openSheetElement = getOpenSheet();
  return openSheetElement ? getters.getHeader(openSheetElement) : null;
} satisfies FunctionImplementation<SheetV1GetHeader>;

export const getActions = function getActions() {
  const openSheetElement = get(openSheet);
  return openSheetElement ? getters.getActions(openSheetElement) : null;
} satisfies FunctionImplementation<SheetV1GetActions>;

export const sheetV1: AsyncNamespaceInput<OttoComponentApi["sheetV1"]> = {
  open,
  close,
  back,
  create,
  getOpenSheet,
  getContent,
  getHeader,
  getActions,
  openEvent: defineEvent(),
  closeEvent: defineEvent(),
  switchEvent: defineEvent(),
  contentLoadedEvent: defineEvent(),
  contentLoadingErrorEvent: defineEvent(),
};

/**
 *
 *
 */
function handleOpenOnClick(event: MouseEvent): void {
  const clickedElement = event.target as HTMLElement;
  const config = parseParameters<OcSheetV1Props>("data-oc-sheet-v1", "open", clickedElement);
  const sheetID = config.id || clickedElement.getAttribute(DATA_SHEET_ID);
  const disabled = clickedElement.getAttribute("disabled");
  if (sheetID && !disabled) {
    open(sheetID);
  }
}

/**
 *
 *
 */
function handleCreateOnClick(event: MouseEvent): void {
  const clickedElement = event.target as HTMLElement;
  const config = parseParameters<OcSheetV1Props>("data-oc-sheet-v1", "create", clickedElement);
  const sheetElement = getSheetElement(config.id);

  if (sheetElement) {
    /*                                            */
    sheetElement.open = true;
  } else {
    const sheet = create(config);
    /*                                                                                           */
    clickedElement.setAttribute("data-oc-sheet-v1-create.id", sheet.id);
  }
}

delegate(window.document, "click", `[${DATA_SHEET_ID}]`, handleOpenOnClick);
delegate(window.document, "click", `[${DATA_SHEET_OPEN}]`, handleOpenOnClick);
delegate(window.document, "click", `[${DATA_SHEET_CREATE}]`, handleCreateOnClick);
