<svelte:options
  customElement={{
    tag: "oc-logo-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      type: { type: "String" },
      size: { type: "String" },
      asButton: { type: "Boolean", attribute: "as-button" },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import { useFullPathHrefGetter } from "../../../common/utils/useFullPathHrefGetter";
  import type { Props } from "./LogoV1.types.js";
  import { logoMap } from "../preCalcedLogoDimensions";

  usePropertyChange();
  useFullPathHrefGetter();

  export let logo: Props["logo"];
  export let size: Props["size"] = "100";
  export let asButton: Props["asButton"] = false;
  export let href: Props["href"] = undefined;
  export let base64Href: Props["base64Href"] = undefined;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  $: logoIdentifier = `pl_logo_${logo}${size === "50" ? "50" : ""}` as keyof typeof logoMap;
  $: svgUrl = `/assets-static/icons/${logoIdentifier}.svg#${logoIdentifier}`;

  $: isAriaHidden = !ocAriaLabel;
</script>

{#if logo}
  <InteractiveElement
    {asButton}
    bind:href
    {base64Href}
    class="logo logo--size-{size}"
    style="--width: {logoMap[logoIdentifier]?.width}; --height: {logoMap[logoIdentifier]?.height}"
    interactiveClass="logo--interactive"
  >
    <svg role="img" width="100%" height="100%" aria-hidden={isAriaHidden} aria-label={ocAriaLabel}>
      <use href={svgUrl} />
    </svg>
  </InteractiveElement>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins.scss";

  :host {
    display: inline-block;
  }

  .logo {
    position: relative;
    display: block;
    padding: 0;
    border: none;
    outline: 0 none;

    --aspect-ratio: calc(var(--width) / var(--height));

    & {
      @include mixins.focus-styles(0.01px);
      @include mixins.no-tap-highlight();
    }

    &--interactive {
      cursor: pointer;

      $offset: calc(-1 * tokens.$oc-component-interactive-image-overlay-offset);

      /*          */
      @media (hover: hover) {
        &:hover {
          &::before {
            content: "";
            position: absolute;
            top: $offset;
            left: $offset;
            right: $offset;
            bottom: $offset;
            border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
            pointer-events: none;
            background-color: tokens.$oc-component-interactive-image-overlay-hover;
          }
        }
      }

      /*           */
      &:active {
        &::before {
          content: "";
          position: absolute;
          top: $offset;
          left: $offset;
          right: $offset;
          bottom: $offset;
          border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
          pointer-events: none;
          background-color: tokens.$oc-component-interactive-image-overlay-active;
        }
      }
    }

    &--size-50 {
      height: tokens.$oc-component-logo-50-default-height;
      width: calc(tokens.$oc-component-logo-50-default-height * var(--aspect-ratio));
    }

    &--size-100 {
      height: tokens.$oc-component-logo-100-default-height;
      width: calc(tokens.$oc-component-logo-100-default-height * var(--aspect-ratio));
    }

    > svg {
      display: block;
      position: relative;
    }
  }
</style>
