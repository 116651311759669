<svelte:options
  customElement={{
    tag: "oc-cinema-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      arrowButtonPosition: { type: "String", attribute: "arrow-button-position" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      cutItems: { type: "Boolean", attribute: "cut-items" },
      stretchItems: { type: "Boolean", attribute: "stretch-items" },
      paginationDots: { type: "Boolean", attribute: "pagination-dots" },
    },
  }}
/>

<script lang="ts">
  import { onMount, tick } from "svelte";
  import { refireScrollEvent } from "../../../common/actions/refireScrollEvent";
  import { ArrowButtonV1 } from "../../../common/components/ArrowButton";
  import PaginationDotsV1 from "../../../common/components/PaginationDotsV1/PaginationDotsV1.svelte";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./CinemaV1.types";
  import { calcItemsPerSlide } from "./math/calcItemsPerSlide";
  import { calcScrollLeft } from "./math/calcScrollLeft";

  usePropertyChange();

  type CinemaItemHTMLElement = {
    isVisible?: boolean;
  } & HTMLElement;

  export let ocAriaLabel: Props["ocAriaLabel"];
  export let arrowButtonPosition: Exclude<Props["arrowButtonPosition"], undefined>;
  export let resizing: Props["resizing"] = "stretch-cut";
  export let paginationDots: Props["paginationDots"] = false;

  export let host: HTMLElement;

  const cinemaStageGap = parseInt(getComputedStyle(host).getPropertyValue("--gap"), 10);
  const cinemaStageCut = parseFloat(getComputedStyle(host).getPropertyValue("--cut"));

  /*                                                        */
  let hideLeftArrow = true;
  let hideRightArrow = true;

  let slideCount = 0;
  let currentSlide = 0;

  let stage: HTMLElement;
  let currentItemIndex = 0;
  let items = Array.from(host.children) as CinemaItemHTMLElement[];

  let stageClientWidth: number = 1;

  let stageScrollLeft: number = 0;
  let stageScrollWidth: number = 0;
  $: if (stage && items && stageClientWidth) {
    /*                                                                    */
    tick().then(() => {
      stageScrollWidth = stage.scrollWidth;
    });
  }

  /*                          */
  $: itemsPerSlide = calcItemsPerSlide(stageClientWidth, cinemaStageGap, cinemaStageCut);

  /*                                                                                         */
  $: stretchItems =
    resizing === "stretch" || (resizing === "stretch-cut" && itemsPerSlide >= items.length);

  $: {
    if (resizing === "stretch-cut") {
      slideCount = Math.ceil(items.length / itemsPerSlide);
      currentSlide = Math.round(currentItemIndex / itemsPerSlide);
    } else {
      slideCount = Math.ceil(stageScrollWidth / stageClientWidth);
      currentSlide = Math.ceil(stageScrollLeft / stageClientWidth);
    }
  }

  $: {
    hideLeftArrow = stageScrollLeft <= cinemaStageGap;
    hideRightArrow = stageScrollLeft >= stageScrollWidth - stageClientWidth - cinemaStageGap;
  }

  const moveTo = (targetSlide: number) => {
    if (resizing === "stretch-cut") {
      const targetItem = host.children.item(targetSlide * itemsPerSlide);
      if (!(targetItem instanceof HTMLElement)) return;
      stage.scrollLeft = calcScrollLeft(targetItem, cinemaStageGap, cinemaStageCut);
    } else {
      stage.scrollLeft = targetSlide * stageClientWidth;
    }
  };

  onMount(() => {
    const intersectionObserverOptions: IntersectionObserverInit = {
      root: stage,
      threshold: [0.999, 1],
    };

    let lastIntersectingItem: CinemaItemHTMLElement | null = null;
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target as CinemaItemHTMLElement;
        target.isVisible = entry.isIntersecting;
        if (entry.isIntersecting) {
          lastIntersectingItem = target;
        }
      });

      currentItemIndex = items.findIndex((i) => i.isVisible);
      if (currentItemIndex === -1) {
        /*                                                            */
        currentItemIndex = items.findIndex((i) => i === lastIntersectingItem);
      }
    }, intersectionObserverOptions);

    items.forEach((i) => intersectionObserver.observe(i));

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(({ removedNodes, addedNodes }) => {
        addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) intersectionObserver.observe(node);
        });
        removedNodes.forEach((node) => {
          if (node instanceof HTMLElement) intersectionObserver.unobserve(node);
        });
        items = Array.from(host.children) as CinemaItemHTMLElement[];
      });
    });
    mutationObserver.observe(host, {
      childList: true,
    });

    return () => {
      intersectionObserver.disconnect();
      mutationObserver.disconnect();
    };
  });
</script>

<div class="cinema">
  <section
    class={`cinema__stage cinema__stage--${resizing}`}
    class:cinema__stage--stretch={stretchItems}
    class:cinema__stage--stretch-cut={resizing === "stretch-cut"}
    style:--items-per-slide={itemsPerSlide}
    bind:this={stage}
    bind:clientWidth={stageClientWidth}
    use:refireScrollEvent={host}
    on:scroll={() => {
      stageScrollLeft = stage.scrollLeft;
    }}
    aria-label={ocAriaLabel}
  >
    <slot />
  </section>
  <ArrowButtonV1
    hidden={hideLeftArrow}
    direction="left"
    {arrowButtonPosition}
    onClick={() => moveTo(currentSlide - 1)}
  ></ArrowButtonV1>
  <ArrowButtonV1
    hidden={hideRightArrow}
    direction="right"
    {arrowButtonPosition}
    onClick={() => moveTo(currentSlide + 1)}
  ></ArrowButtonV1>
</div>

{#if paginationDots}
  <PaginationDotsV1 amountDots={slideCount} selectedDot={currentSlide} {moveTo} />
{/if}

<style lang="scss" global>
  @use "../../../common/scss/mixins/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "math/calcItemBase" as *;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
    max-height: 50rem;
    --gap: #{tokens.$oc-component-cinema-stage-gap-x};
    --cut: #{tokens.$oc-component-cinema-cut-item-visible-percentage};
  }

  .cinema {
    position: relative;

    &__stage {
      display: flex;
      gap: var(--gap);
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none; /*     */
      padding: 4px 0;
      margin: -4px 0;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none; /*                */
      }

      /*                                            */
      @media (prefers-reduced-motion) {
        scroll-behavior: auto;
      }

      &:before,
      &:after {
        content: "";
        flex: 0 0 0;
      }

      ::slotted(*) {
        flex: 0 0 auto;
      }

      &--stretch-cut {
        ::slotted(*) {
          flex: 0 0 calcItemBase(var(--items-per-slide), var(--gap), var(--cut));
        }
      }

      &--stretch {
        ::slotted(*) {
          flex: 1 0 auto;
        }
      }
    }
  }
</style>
