<svelte:options
  customElement={{
    tag: "oc-radio-button-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ formAssociated: true, delegateFocus: true }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import {
    ariaDescriptionWithValidation,
    implicitSubmit,
    refireNonComposableNativeEvent,
    singleSelection,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import { FormLabelIconV1 } from "../../../common/components/FormLabelIcon";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./RadioButtonV1.types";

  usePropertyChange();

  export let name: Props["name"] = undefined;
  export let value: Required<Props>["value"] = "on";
  export let checked: Required<Props>["checked"] = false;
  export let variant: Required<Props>["variant"] = "default";
  export let disabled: Props["disabled"] = false;
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let hint: string | undefined = undefined;
  export let validationMessage: string | undefined = undefined;

  export let internals: ElementInternals;

  export let host: HTMLOcRadioButtonV1Element;

  export function resetForm() {
    /*                            */
    checked = host.hasAttribute("checked");
  }

  $: computedVariant = validationMessage ? "error" : variant;

  $: internals?.setFormValue(checked ? value : null);

  const onChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!event.defaultPrevented) checked = input.checked;
  };
</script>

<label class="radio" class:radio--error={computedVariant === "error"} use:stopLabelClickPropagation>
  <input
    type="radio"
    class="radio__input"
    {checked}
    {name}
    {disabled}
    use:singleSelection={{ host, name, checked }}
    use:refireNonComposableNativeEvent={host}
    use:implicitSubmit={internals}
    use:ariaDescriptionWithValidation={{ validationMessage, hint }}
    on:change={onChange}
    aria-label={ocAriaLabel}
  />
  <!-- The box displays the outline and the icon. This is needed because input element doesn't support pseudo elements. -->
  <span class="radio__circle"></span>
  <span class="radio__label">
    <slot />
    <FormLabelIconV1 variant={computedVariant} />
  </span>
</label>

<style lang="scss" global>
  @use "../../../common/scss/mixins/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .radio {
    position: relative;
    display: block;

    /*                                 */
    padding-left: calc(
      tokens.$oc-component-form-radio-button-container-size +
        tokens.$oc-component-form-radio-button-gap-x
    );

    color: tokens.$oc-component-form-default-label-color;
    font: tokens.$oc-component-form-field-input-font;
  }

  .radio__input {
    all: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: tokens.$oc-component-form-radio-button-container-size;
    height: tokens.$oc-component-form-radio-button-container-size;

    /*                 */
    @include mixins.focus-styles(50%);
  }

  .radio__circle {
    position: absolute;
    top: 0;
    left: 0;
    width: tokens.$oc-component-form-radio-button-container-size;
    height: tokens.$oc-component-form-radio-button-container-size;

    outline: 1px solid tokens.$oc-component-form-default-border-color;
    border-radius: 50%;

    background-color: tokens.$oc-component-form-background-color;
  }

  .radio__label {
    /*                            */
    display: flex;
    gap: tokens.$oc-component-form-state-icon-gap-x;
    align-items: baseline;
  }

  /*                   */
  .radio__input:checked ~ .radio__circle:before {
    $center-dot: 7px;

    content: "";
    display: block;
    position: absolute;
    left: $center-dot;
    top: $center-dot;
    bottom: $center-dot;
    right: $center-dot;

    border-radius: 50%;
    background-color: tokens.$oc-component-form-default-icon-color;
  }

  /*                 */
  .radio--error {
    color: tokens.$oc-component-form-error-label-color;

    .radio__circle {
      outline-color: tokens.$oc-component-form-error-border-color;
    }

    .radio__input:checked ~ .radio__circle:before {
      background: tokens.$oc-component-form-error-icon-color;
    }
  }

  /*                 */
  @media (hover: hover) {
    :host(:hover:enabled) {
      .radio {
        cursor: pointer;
      }

      .radio__circle {
        outline-width: tokens.$oc-semantic-focus-outline-offset;
      }
    }
  }

  /*                  */
  :host(:enabled) {
    .radio__input:active ~ .radio__circle {
      outline-width: tokens.$oc-semantic-focus-outline-offset;
    }

    .radio:not(.radio--error) .radio__input:active ~ .radio__circle {
      outline-color: tokens.$oc-component-form-default-border-color-active;
    }
  }

  /*                    */
  :host(:disabled) {
    .radio {
      color: tokens.$oc-component-form-disabled-label-color;
    }

    .radio__circle {
      outline-color: tokens.$oc-component-form-disabled-border-color;
      background-color: tokens.$oc-component-form-disabled-background-color;

      &:before {
        background: tokens.$oc-component-form-disabled-icon-color;
      }
    }
  }
</style>
