/*                                                   */
import type { EventHandler, HTMLAttributes } from "svelte/elements";
/*                                                         */
import { type AsyncNamespace, type OttoNexus, otto } from "@otto-ec/global-resources/nexus";
import type { CommonAttributes, CommonEvents, CommonProps } from "./component.types.js";

declare global {
  interface HTMLElementEventMap extends CommonEvents<unknown> {}

  /*                                         */
  interface OttoComponentApi {
    version: () => string;
  }

  /*                                    */
  interface NexusInterfaces {
    /**
 *
 *
 */
    components: AsyncNamespace<OttoComponentApi>;
  }
}

/*                                            */
export const components: OttoNexus["components"] = otto.components;

type EventListener<Events> = {
  addEventListener<K extends keyof Events>(
    type: K,
    listener: (this: Element, ev: Events[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener<K extends keyof Events>(
    type: K,
    listener: (this: Element, ev: Events[K]) => any,
    options?: boolean | EventListenerOptions,
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void;
};

type AttributeMethods<Attributes> = {
  setAttribute(name: keyof Attributes, value: string): void;
  removeAttribute(qualifiedName: keyof Attributes): void;
  getAttribute(qualifiedName: keyof Attributes): string | null;
  hasAttribute(qualifiedName: keyof Attributes): boolean;
};

export type OttoComponentsHTMLElement<Attributes, Props, Events, Methods> = HTMLElement &
  Props &
  CommonProps &
  Methods &
  AttributeMethods<Attributes & CommonAttributes> &
  EventListener<Events & CommonEvents<Props>>;

/**
 *
 */
type SvelteEvents<Events extends Record<string, any>, OttoElement extends EventTarget> = {
  [PE in keyof Events as PE extends string ? `on:${PE}` : never]?: EventHandler<
    Events[PE],
    OttoElement
  >;
};

export type OttoComponentsSvelteElement<Element extends EventTarget, Props, Events> = Props &
  CommonProps &
  HTMLAttributes<Element> &
  SvelteEvents<Events & CommonEvents<Props> & HTMLElementEventMap, Element>;
