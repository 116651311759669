<script lang="ts">
  /**
 *
 */
  export let validationMessage: string | undefined = undefined;
</script>

{#if validationMessage}
  <small class="validation-message">{validationMessage}</small>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .validation-message {
    margin-top: tokens.$oc-component-form-hint-gap-y;
    font: tokens.$oc-component-form-hint-font;
    color: tokens.$oc-component-form-error-validation-message-color;
  }
</style>
