/*                                  */
/**
 *
 */
export const logoMap = {
  "pl_logo_app-store": {
    width: 120,
    height: 40
  },
  "pl_logo_ehi": {
    width: 40,
    height: 40
  },
  "pl_logo_facebook": {
    width: 24,
    height: 24
  },
  "pl_logo_github": {
    width: 24,
    height: 24
  },
  "pl_logo_instagram": {
    width: 24,
    height: 24
  },
  "pl_logo_linkedin": {
    width: 24,
    height: 24
  },
  "pl_logo_otto-app": {
    width: 100,
    height: 100
  },
  "pl_logo_otto-deprecated": {
    width: 140,
    height: 52
  },
  "pl_logo_otto-up": {
    width: 172,
    height: 44
  },
  "pl_logo_otto": {
    width: 140,
    height: 52
  },
  "pl_logo_otto50": {
    width: 24,
    height: 24
  },
  "pl_logo_paypal": {
    width: 164,
    height: 40
  },
  "pl_logo_paypal50": {
    width: 24,
    height: 24
  },
  "pl_logo_pinterest": {
    width: 24,
    height: 24
  },
  "pl_logo_play-store": {
    width: 135,
    height: 40
  },
  "pl_logo_roombeez": {
    width: 189,
    height: 40
  },
  "pl_logo_sepa": {
    width: 106,
    height: 40
  },
  "pl_logo_sepa50": {
    width: 24,
    height: 24
  },
  "pl_logo_slogan": {
    width: 159,
    height: 40
  },
  "pl_logo_snapchat": {
    width: 24,
    height: 24
  },
  "pl_logo_soulfully": {
    width: 183,
    height: 40
  },
  "pl_logo_tiktok": {
    width: 24,
    height: 24
  },
  "pl_logo_twitter": {
    width: 24,
    height: 24
  },
  "pl_logo_two-for-fashion": {
    width: 135,
    height: 40
  },
  "pl_logo_up": {
    width: 73,
    height: 44
  },
  "pl_logo_up50": {
    width: 24,
    height: 24
  },
  "pl_logo_xing": {
    width: 24,
    height: 24
  },
  "pl_logo_youtube": {
    width: 24,
    height: 24
  }
} as const;
