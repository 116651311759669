/*                                     */
import type { Class } from "type-fest";

declare global {
  interface Window {
    __components: {
      extend: typeof extendCustomElement;
    };
  }
}

function extendCustomElement({
  delegateFocus,
  formAssociated,
}: {
  formAssociated?: true;
  delegateFocus?: true;
  slots?: string[];
} = {}) {
  return (customElement: Class<HTMLElement>): Class<HTMLElement> => {
    let result = class extends customElement {
      host: HTMLElement = this;
    };

    if (delegateFocus) {
      result = class extends result {
        constructor() {
          super();
          this.attachShadow({ mode: "open", delegatesFocus: true });
        }
      };
    }

    if (formAssociated) {
      result = class extends result {
        static formAssociated = true;

        internals: ElementInternals;

        constructor() {
          super();
          this.internals = this.attachInternals() as unknown as ElementInternals;
        }

        get form(): HTMLFormElement | null {
          return this.internals.form;
        }

        set form(value: unknown) {
          if (typeof value === "string") {
            this.setAttribute("form", value);
          }
        }

        formResetCallback(): void {
          /*                                                         */
          /*         */
          this.resetForm?.();
        }
      };
    }

    return result;
  };
}

/*                                                         */
/*         */
/*                                            */
window.__components ??= {};
/*                                            */
window.__components.extend = extendCustomElement;
