<script lang="ts">
  type FormIconType = "error-hint" | "success-hint" | "warning-hint";
  type FormLabelIconType = "error" | "success" | "warning";

  export let variant: FormLabelIconType | string | undefined = undefined;

  const types = {
    error: "error-hint",
    success: "success-hint",
    warning: "warning-hint",
  } as Record<string, FormIconType>;

  $: type = variant ? types[variant] : undefined;
</script>

{#if type}
  <oc-icon-v1 {type} size={"50"} color="currentColor" />
{/if}

<style lang="scss" global>
  oc-icon-v1 {
    flex: 0 0 auto;
  }
</style>
