<svelte:options
  customElement={{
    tag: "oc-energy-label-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      color: { type: "String" },
      size: { type: "String" },
      variant: { type: "String" },
      version: { type: "String" },
      asButton: { type: "Boolean", attribute: "as-button" },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
    },
  }}
/>

<script lang="ts">
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import { useFullPathHrefGetter } from "../../../common/utils/useFullPathHrefGetter";
  import type { Props } from "./EnergyLabelV1.types.js";

  const EEK_PREFIX = "pl_eek2024";
  const EEK_ASSETS_URL_PREFIX = "/assets-static/icons/";
  const VALID_SIZES: string[] = ["100", "200"];
  const VALID_VERSIONS: string[] = ["default", "uniform"];
  const MAX_PLUS_COUNTS: Record<string, number | undefined> = { default: 3, uniform: 0 };

  usePropertyChange();
  useFullPathHrefGetter();

  export let size: Props["size"] = "100";
  export let variant: Props["variant"] = "A";
  export let color: Props["color"] = "#33a357";
  export let version: Props["version"] = "default";

  export let asButton: Props["asButton"] = false;
  export let href: Props["href"] = undefined;
  export let base64Href: Props["base64Href"] = undefined;

  function svgUrl(svgId: string): string {
    return `${EEK_ASSETS_URL_PREFIX}${svgId}.svg`;
  }

  function svgIdentifier(
    label: string,
    labelSize: string,
    labelVersion: string,
  ): string | undefined {
    if (!VALID_VERSIONS.includes(labelVersion)) return undefined;
    if (!VALID_SIZES.includes(labelSize)) return undefined;

    const plusCount = label.split("+").length - 1;
    if (plusCount > (MAX_PLUS_COUNTS[labelVersion] ?? 0)) return undefined;

    if (plusCount > 0) {
      return `${EEK_PREFIX}_${labelVersion}_${label.toLowerCase()[0]}${plusCount}_${labelSize}`;
    }
    return `${EEK_PREFIX}_${labelVersion}_${label.toLowerCase()}_${labelSize}`;
  }

  $: svgId = svgIdentifier(variant!, size!, version!);
  $: url = svgId ? svgUrl(svgId) : undefined;
  $: labelChar = variant;
</script>

<InteractiveElement
  {asButton}
  bind:href
  {base64Href}
  class="energy-label energy-label--size-{size}"
  interactiveClass="energy-label--interactive"
  style="--energy-label-background-color: {color}"
>
  {#if !!url}
    <span class="fallback oc-copy-100" aria-hidden="true">{labelChar}</span>
    <svg role="img" width="36" height="20">
      <title>Energieverbrauchskennzeichnung {labelChar}</title>
      <use href="{url}#{svgId}" />
    </svg>
  {/if}
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins";

  /**
 *
 */
  $magic-padding-size-100: 0.5rem;
  $magic-padding-size-200: 0.9rem;

  :host {
    display: inline-block;
  }

  .energy-label {
    background: unset;
    position: relative;
    display: block;
    padding: 0;
    border: none;
    outline: 0 none;

    & {
      @include mixins.focus-styles(0.01px);
    }

    .fallback {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
    }

    &--interactive {
      cursor: pointer;

      $offset: calc(-1 * tokens.$oc-component-interactive-image-overlay-offset);

      /*          */
      @media (hover: hover) {
        &:hover {
          &::before {
            content: "";
            position: absolute;
            top: $offset;
            left: $offset;
            right: $offset;
            bottom: $offset;
            border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
            pointer-events: none;
            background-color: tokens.$oc-component-interactive-image-overlay-hover;
          }
        }
      }

      /*           */
      &:active {
        &::before {
          content: "";
          position: absolute;
          top: $offset;
          left: $offset;
          right: $offset;
          bottom: $offset;
          border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
          pointer-events: none;
          background-color: tokens.$oc-component-interactive-image-overlay-active;
        }
      }
    }

    > svg {
      position: relative;

      display: block;
    }

    &--size-100 {
      svg {
        width: 2.25rem;
        height: tokens.$oc-component-energy-label-100-height;
      }
    }

    &--size-200 {
      svg {
        width: 4rem;
        height: tokens.$oc-component-energy-label-200-height;
      }
    }

    use {
      color: var(--energy-label-background-color, gray);
    }
  }
</style>
